<i18n locale="th" lang="yaml" >
page.title : "แก้ไขงานซ่อม : {name} "
page.description : "เปิดงานซ่อมสำหรับ Drone"

service_update.unauthorize : "คุณไม่มีสิทธิ์ในการแก้ไขใบงานซ่อมนี้"
service_update.confirm.title : "ยืนยันการแก้ไขใบงานซ่อมนี้"
service_update.confirm.message : "คุณต้องการยืนยันการแก้ไขใบงานซ่อมตามนี้ ?"
service_update.success : "แก้ไขใบงานซ่อม {service} เรียบร้อย"

service_update.update.label : "แก้ไขรายละเอียดใบงาน"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: service.service_no })"
			:description="$t('page.description')" />
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left small">
				<DroneCard
					class="sticky"
					:drone="droneData.drone"
					:lot="droneData.lot"
					:model="droneData.model"
					:owner="droneData.owner"
					:firmware="droneData.firmware"
					:connect="droneData.connect" />
			</div>
			<div class="mylayout-right">
				<a-card :bordered="false">
					<ServiceForm
						ref="formRef"
						:loading="loading"
						mode="update"
						@submit="handleSubmit">
						<template v-slot:submitSlot="{ formLoading }">
							<a-button
								:disabled="formLoading"
								icon="left"
								size="large"
								:style="{ 'margin-right': '4px' }"
								@click="goBack">
								{{$t('common.back')}}
							</a-button>
							<a-button
								:loading="formLoading"
								type="info"
								html-type="submit"
								class="btn-submit"
								icon="edit"
								size="large">
								{{$t('service_update.update.label')}}
							</a-button>
						</template>
					</ServiceForm>
				</a-card>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import ServiceForm from "@components/service/ServiceForm.vue"
import DroneCard from "@components/drone/DroneCard.vue"
import axios from "axios"
export default {
	components : {
		ServiceForm , DroneCard,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.service.service_no})
		}
	} ,
	data() {
		return {
			service : {} ,
			droneData : {} ,
			loading : false,
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.showPageLoading()
			this.clearBreadcrumbParams(['serviceNo','droneSerialNo'])
			axios.get("/api/services/"+serviceId).then((response) => {
				this.service = response.data.data.service
				this.droneData = this.service.droneData
				// check can update
				if (!(this.service.id &&
				this.service.status != 'close' && this.service.status != 'cancel' &&
				this.$authorize('update','service',{companyId : this.service.company_id}))){
					this.$message.error(this.$t('service_update.unauthorize'))
					this.goBack()
					return
				}

				this.$refs.formRef.updateServiceForm(this.service)
				this.addBreadcrumbParams({
					serviceNo: this.service.service_no ,
					droneSerialNo : this.service.droneData.drone.serialNo
				})
			}).catch((error) => {
				this.fetchError(error)
			}).finally(()=> {
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('service_update.confirm.title') ,
				content : this.$t('service_update.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.put('/api/services/'+this.service.id,formData).then((response)=>{
						const service = response.data.data.service;

						this.$message.success(this.$t('service_update.success',{"service" : service.service_no}))
						this.$router.push({name:'service/view',params: {id : service.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			})
		} ,
		goBack() {
			this.$router.push({name:'service/view',params : {id : this.service.id}})
		}
	}
}
</script>
